<template>
    <section class="mt-5 gap-3 grid grid-cols-12">
        <div class="col-start-1 col-span-12" align>
            <RadioButtonGroup :label="$tc('duration_choice', 2)" id="duration_choice" :options="durationOptions" valueProperty="value" displayProperty="label" v-model="input_data.duration_choice" />
        </div>
        <div class="col-span-3" v-show="input_data.duration_choice === 'choice'">
            <Input :label="$tc('start_date', 1)" type="date" id="start_date" v-model="input_data.start_date" />
        </div>
        <div class="col-span-3" v-show="input_data.duration_choice === 'choice'">
            <Input :label="$tc('end_date', 1)" type="date" id="end_date" v-model="input_data.end_date" />
        </div>
    </section>
    <section class="mt-10" v-show="ready"><LineChart :chartData="chartData" :chartOptions="chartOptions" :styles="chartStyles" /></section>
    <section class="mt-5">
        <table class="divide-y divide-fibonaki-border">
            <thead>
                <tr>
                    <th scope="col" class="px-4 py-1 text-left text-sm font-medium text-gray-900">{{ $tc('date', 1) }}</th>
                    <th scope="col" class="px-4 py-1 text-left text-sm font-medium text-gray-900">{{ $tc('year', 1) }}</th>
                    <th scope="col" class="px-4 py-1 text-left text-sm font-medium text-gray-900">{{ $tc('value', 1) }}</th>
                    <th scope="col" class="px-4 py-1 text-left text-sm font-medium text-gray-900">{{ $tc('evolution', 1) }}</th>
                </tr>
            </thead>
            <tbody v-if="WeekElecDataY1">
                <tr v-for="endex_data in WeekElecDataY1.slice().reverse()" :key="endex_data">
                    <td class="px-4 py-1 whitespace-nowrap text-sm text-gray-500">{{ $filters.getHumanDate(endex_data.cal_date) }}</td>
                    <td class="px-4 py-1 whitespace-nowrap text-sm text-gray-500">{{ endex_data.cal_year }}</td>
                    <td class="px-4 py-1 whitespace-nowrap text-sm text-gray-500">{{ $filters.getDecimalValue(endex_data.value, 2) }} {{ endex_data.unit }}</td>
                    <td class="px-4 py-1 whitespace-nowrap text-sm text-gray-500">
                        <Badge :color="statusColor(endex_data.difference)">{{ $filters.getDecimalValue(endex_data.difference, 2) }} {{ endex_data.unit }}</Badge>
                    </td>
                </tr>
            </tbody>
        </table>
    </section>
    <section class="mt-5">
        <table class="divide-y divide-fibonaki-border">
            <thead>
                <tr>
                    <th scope="col" class="px-4 py-1 text-left text-sm font-medium text-gray-900">{{ $tc('date', 1) }}</th>
                    <th scope="col" class="px-4 py-1 text-left text-sm font-medium text-gray-900">{{ $tc('year', 1) }}</th>
                    <th scope="col" class="px-4 py-1 text-left text-sm font-medium text-gray-900">{{ $tc('value', 1) }}</th>
                    <th scope="col" class="px-4 py-1 text-left text-sm font-medium text-gray-900">{{ $tc('evolution', 1) }}</th>
                </tr>
            </thead>
            <tbody v-if="WeekElecDataY2">
                <tr v-for="endex_data in WeekElecDataY2.slice().reverse()" :key="endex_data">
                    <td class="px-4 py-1 whitespace-nowrap text-sm text-gray-500">{{ $filters.getHumanDate(endex_data.cal_date) }}</td>
                    <td class="px-4 py-1 whitespace-nowrap text-sm text-gray-500">{{ endex_data.cal_year }}</td>
                    <td class="px-4 py-1 whitespace-nowrap text-sm text-gray-500">{{ $filters.getDecimalValue(endex_data.value, 2) }} {{ endex_data.unit }}</td>
                    <td class="px-4 py-1 whitespace-nowrap text-sm text-gray-500">
                        <Badge :color="statusColor(endex_data.difference)">{{ $filters.getDecimalValue(endex_data.difference, 2) }} {{ endex_data.unit }}</Badge>
                    </td>
                </tr>
            </tbody>
        </table>
    </section>
    <section class="mt-5">
        <table class="divide-y divide-fibonaki-border">
            <thead>
                <tr>
                    <th scope="col" class="px-4 py-1 text-left text-sm font-medium text-gray-900">{{ $tc('date', 1) }}</th>
                    <th scope="col" class="px-4 py-1 text-left text-sm font-medium text-gray-900">{{ $tc('year', 1) }}</th>
                    <th scope="col" class="px-4 py-1 text-left text-sm font-medium text-gray-900">{{ $tc('value', 1) }}</th>
                    <th scope="col" class="px-4 py-1 text-left text-sm font-medium text-gray-900">{{ $tc('evolution', 1) }}</th>
                </tr>
            </thead>
            <tbody v-if="WeekElecDataY3">
                <tr v-for="endex_data in WeekElecDataY3.slice().reverse()" :key="endex_data">
                    <td class="px-4 py-1 whitespace-nowrap text-sm text-gray-500">{{ $filters.getHumanDate(endex_data.cal_date) }}</td>
                    <td class="px-4 py-1 whitespace-nowrap text-sm text-gray-500">{{ endex_data.cal_year }}</td>
                    <td class="px-4 py-1 whitespace-nowrap text-sm text-gray-500">{{ $filters.getDecimalValue(endex_data.value, 2) }} {{ endex_data.unit }}</td>
                    <td class="px-4 py-1 whitespace-nowrap text-sm text-gray-500">
                        <Badge :color="statusColor(endex_data.difference)">{{ $filters.getDecimalValue(endex_data.difference, 2) }} {{ endex_data.unit }}</Badge>
                    </td>
                </tr>
            </tbody>
        </table>
    </section>
</template>

<script>
    import EnergyDataService from '@/services/EnergyDataService';
    import LineChart from '@/components/chart/LineChart';
    import Badge from '@/components/general/Badge.vue';
    import moment from 'moment';

    export default {
        components: {
            LineChart,
            Badge,
        },
        async created() {
            await this.getChartData(this.input_data);
            this.ready = true;
        },
        data() {
            return {
                input_data: {
                    start_date: this.getToday(365),
                    end_date: this.getToday(),
                    duration_choice: '365',
                },
                ready: false,
                durationOptions: [
                    { label: this.$tc('amount_of_days', 30), value: '30' },
                    { label: this.$tc('amount_of_months', 6), value: '183' },
                    { label: this.$tc('amount_of_years', 1), value: '365' },
                    { label: this.$tc('amount_of_years', 3), value: '1095' },
                    { label: this.$tc('select_date', 2), value: 'choice' },
                ],
                electricity_endex_data: [],
                chartStyles: {
                    height: '400px',
                },
                chartOptions: {
                    animation: { duration: 0 },
                    responsive: true,
                    maintainAspectRatio: false,
                    legend: {
                        display: true,
                    },
                    tooltips: {
                        titleFontSize: 24,
                        bodyFontSize: 20,
                    },
                    scales: {
                        xAxes: [{ gridLines: { display: false }, ticks: { fontSize: 8 } }],
                        yAxes: [
                            {
                                ticks: {
                                    beginAtZero: false,
                                    callback: function (value) {
                                        return '€' + ' ' + value;
                                    },
                                },
                            },
                        ],
                    },
                },
            };
        },
        computed: {
            chartData() {
                console.log('chart');
                return {
                    labels: this.electricity_endex_data.electricity_labels,
                    datasets: [
                        {
                            label: 'Jaar +1',
                            fill: true,
                            backgroundColor: 'hsla(204, 68%, 58%, 0.1)',
                            pointRadius: 1,
                            borderWidth: 1,
                            borderColor: '#4ca3dd',
                            data: this.electricity_endex_data.electricity_values_y1,
                        },
                        {
                            label: 'Jaar +2',
                            fill: true,
                            backgroundColor: 'hsla(168, 55%, 77%, 0.1)',
                            pointRadius: 1,
                            borderWidth: 1,
                            borderColor: '#A3E4D7',
                            data: this.electricity_endex_data.electricity_values_y2,
                        },
                        {
                            label: 'Jaar +3',
                            fill: true,
                            backgroundColor: 'hsla(282, 44%, 47%, 0.1)',
                            pointRadius: 1,
                            borderWidth: 1,
                            borderColor: '#8E44AD',
                            data: this.electricity_endex_data.electricity_values_y3,
                        },
                    ],
                };
            },
            WeekElecDataY1() {
                if (this.electricity_endex_data.last_week_elec_data) {
                    const result = this.electricity_endex_data.last_week_elec_data.filter((item) => item.type_value === '+1');
                    result.shift();
                    return result;
                } else return '';
            },
            WeekElecDataY2() {
                if (this.electricity_endex_data.last_week_elec_data) {
                    const result = this.electricity_endex_data.last_week_elec_data.filter((item) => item.type_value === '+2');
                    result.shift();
                    return result;
                } else return '';
            },
            WeekElecDataY3() {
                if (this.electricity_endex_data.last_week_elec_data) {
                    const result = this.electricity_endex_data.last_week_elec_data.filter((item) => item.type_value === '+3');
                    result.shift();
                    return result;
                } else return '';
            },
        },
        methods: {
            statusColor(difference) {
                if (difference == 0) {
                    return 'gray';
                } else if (difference < 0) {
                    return 'green';
                } else {
                    return 'red';
                }
            },
            getToday(days) {
                const date = new Date();
                return moment(date).subtract(days, 'days').format('YYYY-MM-DD');
            },
            async getChartData(input_data) {
                const elecEndexDataResponse = await EnergyDataService.getElectricityEndexData(input_data);
                this.electricity_endex_data = elecEndexDataResponse.data.electricity_endex_data;
            },
        },
        watch: {
            'input_data.duration_choice'() {
                this.getChartData(this.input_data);
            },
            'input_data.start_date'() {
                this.getChartData(this.input_data);
            },
            'input_data.end_date'() {
                this.getChartData(this.input_data);
            },
        },
    };
</script>
